import { Link } from "react-router-dom"

const Assets = () => {
    return (
        <>
            <div className="lands">
                <div className="card">
                    <img src={require("../../assets/image/land-1.jpg").default  } className="card-img-top" alt="..." />
                    <div className="card-body">
                        <div className="card-name">
                            <h5>2BR Apartment at Burj Khalifa Fountain View</h5>
                        </div>
                        <div className="card-title">
                            <div className="card-text">
                                <span>Total Share</span>
                                <p>1715 Sq.ft.</p>
                            </div>
                            <div className="card-text">
                                <span>Available Area </span>
                                <p>1500 Sq.ft.</p>
                            </div>
                            <div className="card-text">
                                <span>Sold out </span>
                                <p>215 Sq.ft.</p>
                            </div>
                            <div className="card-text">
                                <span>Claimable Area</span>
                                <p>0</p>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer">
                        <Link to="/" type="button" className="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo">Claim Land</Link>
                    </div>
                </div>
                <div className="card">
                    <img src={require("../../assets/image/land-2.png").default  } className="card-img-top" alt="..." />
                    <div className="card-body">
                        <div className="card-name">
                            <h5>Penthouse at Dubai Marina</h5>
                        </div>
                        <div className="card-title">
                            <div className="card-text">
                                <span>Total Share</span>
                                <p>3724 Sq.ft.</p>
                            </div>
                            <div className="card-text">
                                <span>Available Area </span>
                                <p>3300 Sq.ft.</p>
                            </div>
                            <div className="card-text">
                                <span>Sold out </span>
                                <p>424 Sq.ft.</p>
                            </div>
                            <div className="card-text">
                                <span>Claimable Area</span>
                                <p>0</p>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer">
                        <Link to="/" type="button" className="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo">Claim Land</Link>
                    </div>
                </div>
                <div className="card">
                    <img src={require("../../assets/image/land-3.png").default  } className="card-img-top" alt="..." />
                    <div className="card-body">
                        <div className="card-name">
                            <h5>Studio Apartment with Highway View</h5>
                        </div>
                        <div className="card-title">
                            <div className="card-text">
                                <span>Total Share</span>
                                <p>331 Sq.ft.</p>
                            </div>
                            <div className="card-text">
                                <span>Available Area </span>
                                <p>280 Sq.ft.</p>
                            </div>
                            <div className="card-text">
                                <span>Sold out </span>
                                <p>51 Sq.ft.</p>
                            </div>
                            <div className="card-text">
                                <span>Claimable Area</span>
                                <p>0</p>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer">
                        <Link to="/" type="button" className="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo">Claim Land</Link>
                    </div>
                </div>
                <div className="card">
                    <img src={require("../../assets/image/land-4.png").default  } className="card-img-top" alt="..." />
                    <div className="card-body">
                        <div className="card-name">
                            <h5>Pool View Studio Apartment</h5>
                        </div>
                        <div className="card-title">
                            <div className="card-text">
                                <span>Total Share</span>
                                <p>361 Sq.ft.</p>
                            </div>
                            <div className="card-text">
                                <span>Available Area </span>
                                <p>310 Sq.ft.</p>
                            </div>
                            <div className="card-text">
                                <span>Sold out </span>
                                <p>51 Sq.ft.</p>
                            </div>
                            <div className="card-text">
                                <span>Claimable Area</span>
                                <p>0</p>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer">
                        <Link to="/" type="button" className="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo">Claim Land</Link>
                    </div>
                </div>
                <div className="card">
                    <img src={require("../../assets/image/land-5.png").default  } className="card-img-top" alt="..." />
                    <div className="card-body">
                        <div className="card-name">
                            <h5>Kensington Waters - I at Meydan - 1 BR</h5>
                        </div>
                        <div className="card-title">
                            <div className="card-text">
                                <span>Total Share</span>
                                <p>826 Sq.ft.</p>
                            </div>
                            <div className="card-text">
                                <span>Available Area </span>
                                <p>800 Sq.ft.</p>
                            </div>
                            <div className="card-text">
                                <span>Sold out </span>
                                <p>26 Sq.ft.</p>
                            </div>
                            <div className="card-text">
                                <span>Claimable Area</span>
                                <p>0</p>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer">
                        <Link to="/" type="button" className="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo">Claim Land</Link>
                    </div>
                </div>
                <div className="card">
                    <img src={require("../../assets/image/land-6.png").default  } className="card-img-top" alt="..." />
                    <div className="card-body">
                        <div className="card-name">
                            <h5>Harrington House at Jumeirah Village Circle - 1BR</h5>
                        </div>
                        <div className="card-title">
                            <div className="card-text">
                                <span>Total Share</span>
                                <p>761 Sq.ft.</p>
                            </div>
                            <div className="card-text">
                                <span>Available Area </span>
                                <p>720 Sq.ft.</p>
                            </div>
                            <div className="card-text">
                                <span>Sold out </span>
                                <p>41 Sq.ft.</p>
                            </div>
                            <div className="card-text">
                                <span>Claimable Area</span>
                                <p>0</p>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer">
                        <Link to="/" type="button" className="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo">Claim Land</Link>
                    </div>
                </div>
                <div className="card">
                    <img src={require("../../assets/image/land-7.png").default  } className="card-img-top" alt="..." />
                    <div className="card-body">
                        <div className="card-name">
                            <h5>Private Infinity Pool 1BR Apartment</h5>
                        </div>
                        <div className="card-title">
                            <div className="card-text">
                                <span>Total Share</span>
                                <p>850 Sq.ft.</p>
                            </div>
                            <div className="card-text">
                                <span>Available Area </span>
                                <p>780 Sq.ft.</p>
                            </div>
                            <div className="card-text">
                                <span>Sold out </span>
                                <p>1500 Sq.ft.</p>
                            </div>
                            <div className="card-text">
                                <span>Claimable Area</span>
                                <p>0</p>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer">
                        <Link to="/" type="button" className="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo">Claim Land</Link>
                    </div>
                </div>
            </div>
            <div className="modal fade claim-modal" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body">
                            <img src={require( "../../assets/image/modal-img.png" ).default} alt="" />
                            <p>Land Claim can be done after the completion of Public Sale</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Assets
