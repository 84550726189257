import React from 'react'

function insideCollab() {
    return (
       
            <div className="cricket-bg" >
        <div className="container">
          <div className="cricket__join">
            <div className="cricket__join__itme">
              <div className="cricket__logo">
                <img src="assets/image/lands-pic/cricket-logo.png" alt="" width="300px" />
              </div>
            </div>
            <div className="cricket__join__itme">
              <div className="cricket__join__btn">
                <button className="join-btn">
                  <a href="https://contest.defieleven.com/" target="_blank">
                    <img src="assets/image/lands-pic/join-btn.png" alt="" width="100%" />
                  </a>
                </button>
                <button className="view-btn">
                  <a href="/live">
                    <img src="assets/image/lands-pic/view-btn.png" alt="" width="100%" />
                  </a>
                </button>
              </div>
            </div>
            <div className="cricket__join__itme">
              <div className="stadium-img">
                <img src="assets/image/lands-pic/cricket-stadium.png" alt="" width="100%" />
              </div>
            </div>
          </div>
        </div>
      </div>


    )
}

export default insideCollab
