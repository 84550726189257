import { Switch } from "react-router-dom"
import Assets from "../Container/Assets"
import Lands from "../Container/Lands"
import Map from "../Container/Map"
import CollabLand from "../Container/collabLands"
import VideoPlayer from "../Container/videoPlayer"
import Layout from "../Layout"
import PublicRoute from "./PublicRoute"
import insideCollab from "../Container/insideCollab"



export const Routing =  (
    <Switch>
        <PublicRoute exact path="/" component={Lands} layout={Layout} />
        <PublicRoute exact path="/assets" component={Assets} layout={Layout} />
        <PublicRoute exact path="/map" component={Map} layout={Layout} />
        <PublicRoute exact path="/lands" component={CollabLand} layout={Layout} />
        <PublicRoute exact path="/stadium" component={insideCollab} layout={Layout} />
        <PublicRoute exact path="/live" component={VideoPlayer} layout={Layout} />
    </Switch>
)