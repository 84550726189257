import { Link, useLocation } from "react-router-dom"

const Sidebar = () => {

    const location = useLocation();

    return (
        <div className="sidebar">
            <ul className="sidebar_nav">
                <li className="sidebar_nav-item">
                    <Link className={`sidebar_nav-link ${location.pathname === "/" ? "active" : ""}`} aria-current="page" to="/">
                        <img src={require( "../../assets/image/sid-1.svg" ).default} alt="" width="50px" />
                        <span>Assets</span>
                    </Link>
                </li>
                <li className="sidebar_nav-item">
                    <Link className={`sidebar_nav-link ${location.pathname === "/assets" ? "active" : ""}`} aria-current="page" to="/assets">
                        <img src={require( "../../assets/image/sid-2.svg" ).default} alt="" width="50px" />
                        <span>Properties</span>
                    </Link>
                </li>
                <li className="sidebar_nav-item">
                    <Link className={`sidebar_nav-link ${location.pathname === "/map" ? "active" : ""}`} aria-current="page" to="/map">
                        <img src={require( "../../assets/image/sid-3.svg" ).default} alt="" width="50px" />
                        <span>Map</span>
                    </Link>
                </li>
                <li className="sidebar_nav-item">
                    <Link className={`sidebar_nav-link ${location.pathname === "/lands" ? "active" : ""}`} aria-current="page" to="/lands">
                        <img src={require( "../../assets/image/sid-4.svg" ).default} alt="" width="50px" />
                        <span>Lands</span>
                    </Link>
                </li>
            </ul>
        </div>
    )
}

export default Sidebar
