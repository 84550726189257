const Lands = () => {
  return (
    <>
      <div className="content-tabs">
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Free</button>
          </li>
          <li className="nav-item" role="presentation">
            <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Premium</button>
          </li>
        </ul>
        <div className="modal-btn">
          <button type="button" className="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo">
            <i className="fa fa-plus plus" aria-hidden="true" />
            <span>Request Assets</span>
          </button>
        </div>
      </div>
      <div className="tab-content" id="myTabContent">
        <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
          <div className="assets-content">
            <div className="assets-card">
              <div className="assets-img">
                <img src={require( "../../assets/image/asset-pic/1.png" ).default} alt="1.png" />
                {/* <div className="icon" style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.3rem"
                }}>
                  <img src={require( "../../assets/image/aqr-icon.svg" ).default} alt="" width="20px" />
                  <span>10</span>
                </div> */}
              </div>
              <div className="assets-name">
                <h6>Wooden Weekend Home</h6>
                <span>Available : 120</span>
              </div>
              <div className="assets-btn">
                <div className="buyBtn-group">
                  <button className><i className="fa fa-minus" aria-hidden="true" /></button>
                  <span>1</span>
                  <button className><i className="fa fa-plus" aria-hidden="true" /></button>
                </div>
                <button className="buyBtn btn-secondary">Buy</button>
              </div>
            </div>
            <div className="assets-card">
              <div className="assets-img">
                <img src={require( "../../assets/image/asset-pic/2.png" ).default} alt="2.png" />
                {/* <div className="icon" style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.3rem"
                }}>
                  <img src={require( "../../assets/image/aqr-icon.svg" ).default} alt="" width="20px" />
                  <span>10</span>
                </div> */}
              </div>
              <div className="assets-name">
                <h6>European Native Home</h6>
                <span>Available : 120</span>
              </div>
              <div className="assets-btn">
                <div className="buyBtn-group">
                  <button className><i className="fa fa-minus" aria-hidden="true" /></button>
                  <span>1</span>
                  <button className><i className="fa fa-plus" aria-hidden="true" /></button>
                </div>
                <button className="buyBtn btn-secondary">Buy</button>
              </div>
            </div>
            <div className="assets-card">
              <div className="assets-img">
                <img src={require( "../../assets/image/asset-pic/3.png" ).default} alt="3.png" />
                {/* <div className="icon" style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.3rem"
                }}>
                  <img src={require( "../../assets/image/aqr-icon.svg" ).default} alt="" width="20px" />
                  <span>10</span>
                </div> */}
              </div>
              <div className="assets-name">
                <h6>Corporate Heights</h6>
                <span>Available : 120</span>
              </div>
              <div className="assets-btn">
                <div className="buyBtn-group">
                  <button className><i className="fa fa-minus" aria-hidden="true" /></button>
                  <span>1</span>
                  <button className><i className="fa fa-plus" aria-hidden="true" /></button>
                </div>
                <button className="buyBtn btn-secondary">Buy</button>
              </div>
            </div>
            <div className="assets-card">
              <div className="assets-img">
                <img src={require( "../../assets/image/asset-pic/4.png" ).default} alt="4.png" />
                {/* <div className="icon" style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.3rem"
                }}>
                  <img src={require( "../../assets/image/aqr-icon.svg" ).default} alt="" width="20px" />
                  <span>10</span>
                </div> */}
              </div>
              <div className="assets-name">
                <h6>Outdoor Fountain</h6>
                <span>Available : 120</span>
              </div>
              <div className="assets-btn">
                <div className="buyBtn-group">
                  <button className><i className="fa fa-minus" aria-hidden="true" /></button>
                  <span>1</span>
                  <button className><i className="fa fa-plus" aria-hidden="true" /></button>
                </div>
                <button className="buyBtn btn-secondary">Buy</button>
              </div>
            </div>
            <div className="assets-card">
              <div className="assets-img">
                <img src={require( "../../assets/image/asset-pic/5.png" ).default} alt="5.png" />
                {/* <div className="icon" style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.3rem"
                }}>
                  <img src={require( "../../assets/image/aqr-icon.svg" ).default} alt="" width="20px" />
                  <span>10</span>
                </div> */}
              </div>
              <div className="assets-name">
                <h6>Relax Beach Chair</h6>
                <span>Available : 120</span>
              </div>
              <div className="assets-btn">
                <div className="buyBtn-group">
                  <button className><i className="fa fa-minus" aria-hidden="true" /></button>
                  <span>1</span>
                  <button className><i className="fa fa-plus" aria-hidden="true" /></button>
                </div>
                <button className="buyBtn btn-secondary">Buy</button>
              </div>
            </div>
            <div className="assets-card">
              <div className="assets-img">
                <img src={require( "../../assets/image/asset-pic/6.png" ).default} alt="6.png" />
                {/* <div className="icon" style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.3rem"
                }}>
                  <img src={require( "../../assets/image/aqr-icon.svg" ).default} alt="" width="20px" />
                  <span>10</span>
                </div> */}
              </div>
              <div className="assets-name">
                <h6>County Forest 433</h6>
                <span>Available : 120</span>
              </div>
              <div className="assets-btn">
                <div className="buyBtn-group">
                  <button className><i className="fa fa-minus" aria-hidden="true" /></button>
                  <span>1</span>
                  <button className><i className="fa fa-plus" aria-hidden="true" /></button>
                </div>
                <button className="buyBtn btn-secondary">Buy</button>
              </div>
            </div>
            <div className="assets-card">
              <div className="assets-img">
                <img src={require( "../../assets/image/asset-pic/7.png" ).default} alt="7.png" />
                {/* <div className="icon" style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.3rem"
                }}>
                  <img src={require( "../../assets/image/aqr-icon.svg" ).default} alt="" width="20px" />
                  <span>10</span>
                </div> */}
              </div>
              <div className="assets-name">
                <h6>Home Sale Location</h6>
                <span>Available : 120</span>
              </div>
              <div className="assets-btn">
                <div className="buyBtn-group">
                  <button className><i className="fa fa-minus" aria-hidden="true" /></button>
                  <span>1</span>
                  <button className><i className="fa fa-plus" aria-hidden="true" /></button>
                </div>
                <button className="buyBtn btn-secondary">Buy</button>
              </div>
            </div>
            <div className="assets-card">
              <div className="assets-img">
                <img src={require( "../../assets/image/asset-pic/8.png" ).default} alt="8.png" />
                {/* <div className="icon" style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.3rem"
                }}>
                  <img src={require( "../../assets/image/aqr-icon.svg" ).default} alt="" width="20px" />
                  <span>10</span>
                </div> */}
              </div>
              <div className="assets-name">
                <h6>County Forest 432</h6>
                <span>Available : 120</span>
              </div>
              <div className="assets-btn">
                <div className="buyBtn-group">
                  <button className><i className="fa fa-minus" aria-hidden="true" /></button>
                  <span>1</span>
                  <button className><i className="fa fa-plus" aria-hidden="true" /></button>
                </div>
                <button className="buyBtn btn-secondary">Buy</button>
              </div>
            </div>
            <div className="assets-card">
              <div className="assets-img">
                <img src={require( "../../assets/image/asset-pic/9.png" ).default} alt="9.png" />
                {/* <div className="icon" style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.3rem"
                }}>
                  <img src={require( "../../assets/image/aqr-icon.svg" ).default} alt="" width="20px" />
                  <span>10</span>
                </div> */}
              </div>
              <div className="assets-name">
                <h6>Charolate Brigde</h6>
                <span>Available : 120</span>
              </div>
              <div className="assets-btn">
                <div className="buyBtn-group">
                  <button className><i className="fa fa-minus" aria-hidden="true" /></button>
                  <span>1</span>
                  <button className><i className="fa fa-plus" aria-hidden="true" /></button>
                </div>
                <button className="buyBtn btn-secondary">Buy</button>
              </div>
            </div>
            <div className="assets-card">
              <div className="assets-img">
                <img src={require( "../../assets/image/asset-pic/10.png" ).default} alt="10.png" />
                {/* <div className="icon" style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.3rem"
                }}>
                  <img src={require( "../../assets/image/aqr-icon.svg" ).default} alt="" width="20px" />
                  <span>10</span>
                </div> */}
              </div>
              <div className="assets-name">
                <h6>Spring Forest 0322</h6>
                <span>Available : 120</span>
              </div>
              <div className="assets-btn">
                <div className="buyBtn-group">
                  <button className><i className="fa fa-minus" aria-hidden="true" /></button>
                  <span>1</span>
                  <button className><i className="fa fa-plus" aria-hidden="true" /></button>
                </div>
                <button className="buyBtn btn-secondary">Buy</button>
              </div>
            </div>
            <div className="assets-card">
              <div className="assets-img">
                <img src={require( "../../assets/image/asset-pic/11.png" ).default} alt="11.png" />
                {/* <div className="icon" style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.3rem"
                }}>
                  <img src={require( "../../assets/image/aqr-icon.svg" ).default} alt="" width="20px" />
                  <span>10</span>
                </div> */}
              </div>
              <div className="assets-name">
                <h6>County Forest 436</h6>
                <span>Available : 120</span>
              </div>
              <div className="assets-btn">
                <div className="buyBtn-group">
                  <button className><i className="fa fa-minus" aria-hidden="true" /></button>
                  <span>1</span>
                  <button className><i className="fa fa-plus" aria-hidden="true" /></button>
                </div>
                <button className="buyBtn btn-secondary">Buy</button>
              </div>
            </div>
            <div className="assets-card">
              <div className="assets-img">
                <img src={require( "../../assets/image/asset-pic/12.png" ).default} alt="12.png" />
                {/* <div className="icon" style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.3rem"
                }}>
                  <img src={require( "../../assets/image/aqr-icon.svg" ).default} alt="" width="20px" />
                  <span>10</span>
                </div> */}
              </div>
              <div className="assets-name">
                <h6>Outdoor Fountain E1</h6>
                <span>Available : 120</span>
              </div>
              <div className="assets-btn">
                <div className="buyBtn-group">
                  <button className><i className="fa fa-minus" aria-hidden="true" /></button>
                  <span>1</span>
                  <button className><i className="fa fa-plus" aria-hidden="true" /></button>
                </div>
                <button className="buyBtn btn-secondary">Buy</button>
              </div>
            </div>
            <div className="assets-card">
              <div className="assets-img">
                <img src={require( "../../assets/image/asset-pic/13.png" ).default} alt="13.png" />
                {/* <div className="icon" style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.3rem"
                }}>
                  <img src={require( "../../assets/image/aqr-icon.svg" ).default} alt="" width="20px" />
                  <span>10</span>
                </div> */}
              </div>
              <div className="assets-name">
                <h6>Outdoor Fountain E2</h6>
                <span>Available : 120</span>
              </div>
              <div className="assets-btn">
                <div className="buyBtn-group">
                  <button className><i className="fa fa-minus" aria-hidden="true" /></button>
                  <span>1</span>
                  <button className><i className="fa fa-plus" aria-hidden="true" /></button>
                </div>
                <button className="buyBtn btn-secondary">Buy</button>
              </div>
            </div>
            <div className="assets-card">
              <div className="assets-img">
                <img src={require( "../../assets/image/asset-pic/14.png" ).default} alt="14.png" />
                {/* <div className="icon" style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.3rem"
                }}>
                  <img src={require( "../../assets/image/aqr-icon.svg" ).default} alt="" width="20px" />
                  <span>10</span>
                </div> */}
              </div>
              <div className="assets-name">
                <h6>Pool View Brigde</h6>
                <span>Available : 120</span>
              </div>
              <div className="assets-btn">
                <div className="buyBtn-group">
                  <button className><i className="fa fa-minus" aria-hidden="true" /></button>
                  <span>1</span>
                  <button className><i className="fa fa-plus" aria-hidden="true" /></button>
                </div>
                <button className="buyBtn btn-secondary">Buy</button>
              </div>
            </div>
            <div className="assets-card">
              <div className="assets-img">
                <img src={require( "../../assets/image/asset-pic/15.png" ).default} alt="15.png" />
                {/* <div className="icon" style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.3rem"
                }}>
                  <img src={require( "../../assets/image/aqr-icon.svg" ).default} alt="" width="20px" />
                  <span>10</span>
                </div> */}
              </div>
              <div className="assets-name">
                <h6>King Villa Mark</h6>
                <span>Available : 120</span>
              </div>
              <div className="assets-btn">
                <div className="buyBtn-group">
                  <button className><i className="fa fa-minus" aria-hidden="true" /></button>
                  <span>1</span>
                  <button className><i className="fa fa-plus" aria-hidden="true" /></button>
                </div>
                <button className="buyBtn btn-secondary">Buy</button>
              </div>
            </div>
            <div className="assets-card">
              <div className="assets-img">
                <img src={require( "../../assets/image/asset-pic/16.png" ).default} alt="16.png" />
                {/* <div className="icon" style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.3rem"
                }}>
                  <img src={require( "../../assets/image/aqr-icon.svg" ).default} alt="" width="20px" />
                  <span>10</span>
                </div> */}
              </div>
              <div className="assets-name">
                <h6>Hill View Gargen</h6>
                <span>Available : 120</span>
              </div>
              <div className="assets-btn">
                <div className="buyBtn-group">
                  <button className><i className="fa fa-minus" aria-hidden="true" /></button>
                  <span>1</span>
                  <button className><i className="fa fa-plus" aria-hidden="true" /></button>
                </div>
                <button className="buyBtn btn-secondary">Buy</button>
              </div>
            </div>
            <div className="assets-card">
              <div className="assets-img">
                <img src={require( "../../assets/image/asset-pic/17.png" ).default} alt="17.png" />
                {/* <div className="icon" style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.3rem"
                }}>
                  <img src={require( "../../assets/image/aqr-icon.svg" ).default} alt="" width="20px" />
                  <span>10</span>
                </div> */}
              </div>
              <div className="assets-name">
                <h6>Weekend Trip T2</h6>
                <span>Available : 120</span>
              </div>
              <div className="assets-btn">
                <div className="buyBtn-group">
                  <button className><i className="fa fa-minus" aria-hidden="true" /></button>
                  <span>1</span>
                  <button className><i className="fa fa-plus" aria-hidden="true" /></button>
                </div>
                <button className="buyBtn btn-secondary">Buy</button>
              </div>
            </div>
            <div className="assets-card">
              <div className="assets-img">
                <img src={require( "../../assets/image/asset-pic/18.png" ).default} alt="18.png" />
                {/* <div className="icon" style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.3rem"
                }}>
                  <img src={require( "../../assets/image/aqr-icon.svg" ).default} alt="" width="20px" />
                  <span>10</span>
                </div> */}
              </div>
              <div className="assets-name">
                <h6>King Villa Fountain F54</h6>
                <span>Available : 120</span>
              </div>
              <div className="assets-btn">
                <div className="buyBtn-group">
                  <button className><i className="fa fa-minus" aria-hidden="true" /></button>
                  <span>1</span>
                  <button className><i className="fa fa-plus" aria-hidden="true" /></button>
                </div>
                <button className="buyBtn btn-secondary">Buy</button>
              </div>
            </div>
            <div className="assets-card">
              <div className="assets-img">
                <img src={require( "../../assets/image/asset-pic/19.png" ).default} alt="19.png" />
                {/* <div className="icon" style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.3rem"
                }}>
                  <img src={require( "../../assets/image/aqr-icon.svg" ).default} alt="" width="20px" />
                  <span>10</span>
                </div> */}
              </div>
              <div className="assets-name">
                <h6>Country Forest 593</h6>
                <span>Available : 120</span>
              </div>
              <div className="assets-btn">
                <div className="buyBtn-group">
                  <button className><i className="fa fa-minus" aria-hidden="true" /></button>
                  <span>1</span>
                  <button className><i className="fa fa-plus" aria-hidden="true" /></button>
                </div>
                <button className="buyBtn btn-secondary">Buy</button>
              </div>
            </div>
            <div className="assets-card">
              <div className="assets-img">
                <img src={require( "../../assets/image/asset-pic/19.png" ).default} alt="19.png" />
                {/* <div className="icon" style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.3rem"
                }}>
                  <img src={require( "../../assets/image/aqr-icon.svg" ).default} alt="" width="20px" />
                  <span>10</span>
                </div> */}
              </div>
              <div className="assets-name">
                <h6>Country Forest 633</h6>
                <span>Available : 120</span>
              </div>
              <div className="assets-btn">
                <div className="buyBtn-group">
                  <button className><i className="fa fa-minus" aria-hidden="true" /></button>
                  <span>1</span>
                  <button className><i className="fa fa-plus" aria-hidden="true" /></button>
                </div>
                <button className="buyBtn btn-secondary">Buy</button>
              </div>
            </div>
          </div>
        </div>
        <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
          <div className="assets-content">
            <div className="assets-card">
              <div className="assets-img">
                <img src={require( "../../assets/image/asset-pic/1.png" ).default} alt="1.png" />
                <div className="icon" style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.3rem"
                }}>
                  <img src={require( "../../assets/image/aqr-icon.svg" ).default} alt="" width="20px" />
                  <span>10</span>
                </div>
              </div>
              <div className="assets-name">
                <h6>Wooden Weekend Home</h6>
                <span>Available : 120</span>
              </div>
              <div className="assets-btn">
                <div className="buyBtn-group">
                  <button className><i className="fa fa-minus" aria-hidden="true" /></button>
                  <span>1</span>
                  <button className><i className="fa fa-plus" aria-hidden="true" /></button>
                </div>
                <button className="buyBtn btn-secondary">Buy</button>
              </div>
            </div>
            <div className="assets-card">
              <div className="assets-img">
                <img src={require( "../../assets/image/asset-pic/2.png" ).default} alt="2.png" />
                <div className="icon" style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.3rem"
                }}>
                  <img src={require( "../../assets/image/aqr-icon.svg" ).default} alt="" width="20px" />
                  <span>10</span>
                </div>
              </div>
              <div className="assets-name">
                <h6>European Native Home</h6>
                <span>Available : 120</span>
              </div>
              <div className="assets-btn">
                <div className="buyBtn-group">
                  <button className><i className="fa fa-minus" aria-hidden="true" /></button>
                  <span>1</span>
                  <button className><i className="fa fa-plus" aria-hidden="true" /></button>
                </div>
                <button className="buyBtn btn-secondary">Buy</button>
              </div>
            </div>
            <div className="assets-card">
              <div className="assets-img">
                <img src={require( "../../assets/image/asset-pic/3.png" ).default} alt="3.png" />
                <div className="icon" style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.3rem"
                }}>
                  <img src={require( "../../assets/image/aqr-icon.svg" ).default} alt="" width="20px" />
                  <span>10</span>
                </div>
              </div>
              <div className="assets-name">
                <h6>Corporate Heights</h6>
                <span>Available : 120</span>
              </div>
              <div className="assets-btn">
                <div className="buyBtn-group">
                  <button className><i className="fa fa-minus" aria-hidden="true" /></button>
                  <span>1</span>
                  <button className><i className="fa fa-plus" aria-hidden="true" /></button>
                </div>
                <button className="buyBtn btn-secondary">Buy</button>
              </div>
            </div>
            <div className="assets-card">
              <div className="assets-img">
                <img src={require( "../../assets/image/asset-pic/4.png" ).default} alt="4.png" />
                <div className="icon" style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.3rem"
                }}>
                  <img src={require( "../../assets/image/aqr-icon.svg" ).default} alt="" width="20px" />
                  <span>10</span>
                </div>
              </div>
              <div className="assets-name">
                <h6>Outdoor Fountain</h6>
                <span>Available : 120</span>
              </div>
              <div className="assets-btn">
                <div className="buyBtn-group">
                  <button className><i className="fa fa-minus" aria-hidden="true" /></button>
                  <span>1</span>
                  <button className><i className="fa fa-plus" aria-hidden="true" /></button>
                </div>
                <button className="buyBtn btn-secondary">Buy</button>
              </div>
            </div>
            <div className="assets-card">
              <div className="assets-img">
                <img src={require( "../../assets/image/asset-pic/5.png" ).default} alt="5.png" />
                <div className="icon" style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.3rem"
                }}>
                  <img src={require( "../../assets/image/aqr-icon.svg" ).default} alt="" width="20px" />
                  <span>10</span>
                </div>
              </div>
              <div className="assets-name">
                <h6>Relax Beach Chair</h6>
                <span>Available : 120</span>
              </div>
              <div className="assets-btn">
                <div className="buyBtn-group">
                  <button className><i className="fa fa-minus" aria-hidden="true" /></button>
                  <span>1</span>
                  <button className><i className="fa fa-plus" aria-hidden="true" /></button>
                </div>
                <button className="buyBtn btn-secondary">Buy</button>
              </div>
            </div>
            <div className="assets-card">
              <div className="assets-img">
                <img src={require( "../../assets/image/asset-pic/6.png" ).default} alt="6.png" />
                <div className="icon" style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.3rem"
                }}>
                  <img src={require( "../../assets/image/aqr-icon.svg" ).default} alt="" width="20px" />
                  <span>10</span>
                </div>
              </div>
              <div className="assets-name">
                <h6>County Forest 433</h6>
                <span>Available : 120</span>
              </div>
              <div className="assets-btn">
                <div className="buyBtn-group">
                  <button className><i className="fa fa-minus" aria-hidden="true" /></button>
                  <span>1</span>
                  <button className><i className="fa fa-plus" aria-hidden="true" /></button>
                </div>
                <button className="buyBtn btn-secondary">Buy</button>
              </div>
            </div>
            <div className="assets-card">
              <div className="assets-img">
                <img src={require( "../../assets/image/asset-pic/7.png" ).default} alt="7.png" />
                <div className="icon" style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.3rem"
                }}>
                  <img src={require( "../../assets/image/aqr-icon.svg" ).default} alt="" width="20px" />
                  <span>10</span>
                </div>
              </div>
              <div className="assets-name">
                <h6>Home Sale Location</h6>
                <span>Available : 120</span>
              </div>
              <div className="assets-btn">
                <div className="buyBtn-group">
                  <button className><i className="fa fa-minus" aria-hidden="true" /></button>
                  <span>1</span>
                  <button className><i className="fa fa-plus" aria-hidden="true" /></button>
                </div>
                <button className="buyBtn btn-secondary">Buy</button>
              </div>
            </div>
            <div className="assets-card">
              <div className="assets-img">
                <img src={require( "../../assets/image/asset-pic/8.png" ).default} alt="8.png" />
                <div className="icon" style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.3rem"
                }}>
                  <img src={require( "../../assets/image/aqr-icon.svg" ).default} alt="" width="20px" />
                  <span>10</span>
                </div>
              </div>
              <div className="assets-name">
                <h6>County Forest 432</h6>
                <span>Available : 120</span>
              </div>
              <div className="assets-btn">
                <div className="buyBtn-group">
                  <button className><i className="fa fa-minus" aria-hidden="true" /></button>
                  <span>1</span>
                  <button className><i className="fa fa-plus" aria-hidden="true" /></button>
                </div>
                <button className="buyBtn btn-secondary">Buy</button>
              </div>
            </div>
            <div className="assets-card">
              <div className="assets-img">
                <img src={require( "../../assets/image/asset-pic/9.png" ).default} alt="9.png" />
                <div className="icon" style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.3rem"
                }}>
                  <img src={require( "../../assets/image/aqr-icon.svg" ).default} alt="" width="20px" />
                  <span>10</span>
                </div>
              </div>
              <div className="assets-name">
                <h6>Charolate Brigde</h6>
                <span>Available : 120</span>
              </div>
              <div className="assets-btn">
                <div className="buyBtn-group">
                  <button className><i className="fa fa-minus" aria-hidden="true" /></button>
                  <span>1</span>
                  <button className><i className="fa fa-plus" aria-hidden="true" /></button>
                </div>
                <button className="buyBtn btn-secondary">Buy</button>
              </div>
            </div>
            <div className="assets-card">
              <div className="assets-img">
                <img src={require( "../../assets/image/asset-pic/9.png" ).default} alt="9.png" />
                <div className="icon" style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.3rem"
                }}>
                  <img src={require( "../../assets/image/aqr-icon.svg" ).default} alt="" width="20px" />
                  <span>10</span>
                </div>
              </div>
              <div className="assets-name">
                <h6>Charolate Brigde</h6>
                <span>Available : 120</span>
              </div>
              <div className="assets-btn">
                <div className="buyBtn-group">
                  <button className><i className="fa fa-minus" aria-hidden="true" /></button>
                  <span>1</span>
                  <button className><i className="fa fa-plus" aria-hidden="true" /></button>
                </div>
                <button className="buyBtn btn-secondary">Buy</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal  fade claim-modal" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="mb-3">
                  <input type="text" className="form-control" id="recipient-name" placeholder="Aseets Name" />
                </div>
                <div className="mb-3">
                  <textarea className="form-control" id="message-text" placeholder="Request Proposal"></textarea>
                </div>
              </form>
              <button type="button" className="btn btn-secondary">Request Asset</button>
            </div>

          </div>
        </div>
      </div>
    </>
  )
}

export default Lands
