import React from 'react'

function CollabLand() {
    return (
      
        <div className="cricket-bg">
        <div className="container">
          <div className="cricket__lands">
            <div className="cricket__lands__list">
              <div className="cricket__lands__itme">
                <a className="itme1" href="/stadium">
                  <img src="assets/image/lands-pic/Asset 10.png" alt="" width="100%" />
                </a>
              </div>
              <div className="cricket__lands__itme">
                <div className="itme2">
                  <h2>COMING <span>SOON</span></h2>
                </div>
              </div>
              <div className="cricket__lands__itme">
                <div className="itme3">
                  <h2>COMING <span>SOON</span></h2>
                </div>
              </div>
              <div className="cricket__lands__itme">
                <div className="itme4">
                  <h2>COMING <span>SOON</span></h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
     
    )
}

export default CollabLand
