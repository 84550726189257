import React from 'react'
import Header from './Header'
import Sidebar from './Sidebar'
import { Link, useLocation } from "react-router-dom"

const Layout = ( { children } ) => {

    const location = useLocation();

    return (
        <div className="wrapper">
            <Header />
            <div className="body">
                <Sidebar />
               {location.pathname == "/lands" || location.pathname == "/live" ? children:  location.pathname == "/stadium"?children: <div className="main"><div className="container">{children}</div></div>}
            </div>
        </div>
    )
}

export default Layout
