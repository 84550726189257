import { useWeb3React } from "@web3-react/core";
import { useState } from "react";
import { Link } from "react-router-dom";
import WalletPopup from "../../components/Popup/Popup";
import WalletDetailsPopup from "../../components/WalletDetailsPopup/WalletDetailsPopup";
const Header = () => {

    //  const { active, account } = useWeb3React();
    const [walletOpen, setWalletOpen] = useState( false );
    const closeModal = () => setWalletOpen( false );

    const [changePopupOpen, setChangePopupOpen] = useState( false );
    const closeModalChangePopup = () => setChangePopupOpen( false );


    return (
        <>
            {walletOpen && (
                <WalletPopup
                    walletOpen={walletOpen}
                    setWalletOpen={setWalletOpen}
                    closeModal={closeModal}
                />
            )}
            {changePopupOpen && (
                <WalletDetailsPopup
                    setWalletOpen={setWalletOpen}
                    changePopupOpen={changePopupOpen}
                    setChangePopupOpen={setChangePopupOpen}
                    closeModal={closeModalChangePopup}
                />
            )}
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="container-fluid">
                    <Link className="navbar-brand" to="/">
                        <img src={require( "../../assets/image/logo.png" ).default} alt="Aqarland Logo" width="140px" />
                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false"
                        aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse justify-content-center" id="navbarNavDropdown">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                            <a className="nav-link" href="https://aqarchain.io/">Home</a>                            </li>
                            <li className="nav-item">
                               <a className="nav-link" href="https://aqarchain.io/discover">Discover</a>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link active" to="/">Aqarland</Link>
                            </li>
                            {/* <div className="navbar_itme-dex">
                                <Link className="btn btn-primary" type="button" to="/">Connect Wallet</Link>
                                <Link className="btn btn-secondary" type="button" to="/">Login/Sign Up</Link>
                            </div> */}
                        </ul>
                    </div>
                    <div className="navbar_itme-mob" style={{ display: "flex", gap: "0.5rem" }}>
                        <button
                            className="btn btn-primary"
                            type="button"
                        //     onClick={active
                        //     ? () => setChangePopupOpen( true )
                        //     : () => setWalletOpen( true )
                        // }
                        >Connect Wallet</button>
                        <a className="btn btn-secondary" href="https://aqarchain.io/login">Login/Sign up</a>
                        {/* <Link className="btn btn-secondary" type="button" to="/">Login/Sign Up</Link> */}
                    </div>
                </div>
            </nav>
            <div className="header">
                <div className="header-text">
                    <p>Hello ! Folks Welcome to Aqarland</p>
                </div>
            </div>
        </>
    )
}

export default Header
