import React from 'react'

function VideoPlayer() {
    return (
        <div className="cricket-bg">
        <div className="cricket__container">
          <div className="cricket__player__con">
            <div className="video__player">
              <iframe width={1280} height={720} src="https://www.youtube.com/embed/54_o9tEvnfY" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen>
              </iframe>
            </div>
          </div>
        </div>
      </div>
    )
}

export default VideoPlayer
