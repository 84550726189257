const Map = () => {
    return (
        <div className="map_container">
            <div className="map_container-itme">
                <div className="card card-1">
                    <img src={require("../../assets/image/land-5.png").default} className="card-img-top" alt="..." width="100px" />
                    <div className="card-body">
                        <p className="card-text">Kensington
                            Waters - I at
                            Meydan - 1 BR </p>
                    </div>
                </div>
                <div className="card card-2">
                    <img src={require("../../assets/image/land-6.png").default} className="card-img-top" alt="..." />
                    <div className="card-body">
                        <p className="card-text">Harrington House
                            at Jumeirah
                            Village Circle - 1BR </p>
                    </div>
                </div>
            </div>
            <div className="map_container-itme2">
                <div className="card card-3">
                    <img src={require("../../assets/image/land-2.png").default} className="card-img-top" alt="..." width="100px" />
                    <div className="card-body">
                        <p className="card-text">Penthouse at
                            Dubai Marina </p>
                    </div>
                </div>
                <div className="card card-4">
                    <img src={require("../../assets/image/land-3.png").default} className="card-img-top" alt="..." />
                    <div className="card-body">
                        <p className="card-text">Studio Apartment
                            with Highway View</p>
                    </div>
                </div>
                <div className="card card-5">
                    <img src={require("../../assets/image/land-4.png").default} className="card-img-top" alt="..." />
                    <div className="card-body">
                        <p className="card-text">Pool View Studio Apartment</p>
                    </div>
                </div>
            </div>
            <div className="map_container-itme3">
                <div className="card card-1">
                    <img src={require("../../assets/image/land-1.jpg").default} className="card-img-top" alt="..." width="100px" />
                    <div className="card-body">
                        <p className="card-text">2BR Apartment
                            at Burj Khalifa
                            Fountain View</p>
                    </div>
                </div>
                <div className="card card-2">
                    <img src={require("../../assets/image/land-7.png").default} className="card-img-top" alt="..." />
                    <div className="card-body">
                        <p className="card-text">Private Infinity Pool
                            1BR Apartment </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Map
