import { BrowserRouter } from "react-router-dom";
import { Routing } from "./Routing/index"

function App () {
  return (
    <BrowserRouter>
      {Routing} 
    </BrowserRouter>
  );
}

export default App;
